export default function Card({img, altText = '', title, text}) {
  return (
    <div className='card'>
        <div className='img-container'>
            <img src={img} alt={altText} />
        </div>
        <h3>{title}</h3>
        <p>{text}</p>
    </div>
  )
}
