import React from 'react'

export default function OurWork() {
  return (
    <section className='about-us'>
    <div className='anchor-link' id='about-us'></div>
        <div className='container'>
            <h2 className='text-center'>About us</h2>
            <p className='text-center'>We are a family run business based around Surrey, our company has been established for 50+ years. For any enquires or a free consultation please contact us. <br/><br/>For any additional information please look us up on our <a href='https://www.instagram.com/jjbarneytreecare/' target="_blank" rel='noreferrer' className='link'>Facebook</a> or <a href='https://www.instagram.com/jjbarneytreecare/' target="_blank" rel='noreferrer' className='link'>Instagram</a> page.</p>
        </div>
    </section>
  )
}
