import React from 'react'
import { BsInstagram, BsFacebook } from 'react-icons/bs'

export default function Footer() {
  return (
    <footer>
        <div className='container'>
            <div className='social-links'>
              <a href='https://www.facebook.com/JJBarneytreecare' target="_blank" rel='noreferrer'>
                <BsFacebook color='#006f37' size='3em'/>
              </a>
              <a href='https://www.instagram.com/jjbarneytreecare/' target="_blank" rel='noreferrer'>
                <BsInstagram color='#006f37' size='3em'/>
              </a>
            </div>

            <p className='text-center'>JJ Barney & Sons &copy; {new Date().getFullYear()}</p>
        </div>
    </footer>
  )
}
