import React from 'react'
import LogoNoBg from '../../assets/imgs/logo-no-bg.png'
import ForestImg from '../../assets/imgs/forest.jpg'

export default function Hero() {
  return (
    <div className='hero'>
        <img src={ForestImg} className="bg-img" alt='forest' style={{objectFit: "stretch"}} />
        <div className='hero-text'>
            {/* <h1>JJ Barney <span className='no-wrap'>& Sons</span></h1> */}
            <img src={LogoNoBg} className="logo-img" alt="logo" />
            <p>Surrey tree surgeon specialists with over <span className='no-wrap'>50 years</span> of experience</p>
            <a href='#services' rel='noreferrer' className='button'>View our Services</a>
        </div>
    </div>
  )
}
