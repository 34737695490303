import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import BeforeImgOne from '../../assets/imgs/before-1.jpg'
import BeforeImgTwo from '../../assets/imgs/before-2.jpg'
import BeforeImgThree from '../../assets/imgs/before-3.jpg'
import AfterImgOne from '../../assets/imgs/after-1.jpg'
import AfterImgTwo from '../../assets/imgs/after-2.jpg'
import AfterImgThree from '../../assets/imgs/after-3.jpg'

export default function OurWork() {
  return (
    <section className='our-work'>
    <div className='anchor-link' id='our-work'></div>
        <div className='container'>
            <h2 className='text-center'>Our work</h2>

            <Carousel showArrows={true} swipeable={false} infiniteLoop={true} className='our-work-carousel'>
                <div className='carousel-slide'>
                    <img src={BeforeImgOne} alt='hedge before 1'/>
                    <img src={AfterImgOne} alt='hedge after 1' />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
                <div className='carousel-slide'>
                    <img src={BeforeImgTwo} alt='hedge before 2' />
                    <img src={AfterImgTwo} alt='hedge after 2' />
                    {/* <p className="legend">Legend 2</p> */}
                </div>
                <div className='carousel-slide'>
                    <img src={BeforeImgThree} alt='hedge before 3' />
                    <img src={AfterImgThree} alt='hedge after 3' />
                    {/* <p className="legend">Legend 3</p> */}
                </div>
            </Carousel>
        </div>
    </section>
  )
}
