import './App.scss'
import Header from './components/layout/Header';
import Hero from './components/sections/Hero';
import Services from './components/sections/Services';
import AboutUs from './components/sections/AboutUs';
import OurWork from './components/sections/OurWork';
import ContactUs from './components/sections/Contact';
import Footer from './components/layout/Footer';

function App() {
  return (
    <div className="App">
      <Header/>
      <Hero/>
      <Services/>
      <AboutUs/>
      <OurWork/>
      <ContactUs/>
      <Footer/>
    </div>
  );
}

export default App;
