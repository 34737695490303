import React from 'react'

export default function ContactUs() {
  return (
    <section className='contact-us'>
    <div className='anchor-link' id='contact'></div>
        <div className='container'>
            <div className='contact-wrapper'>
                <div className='contact-card'>
                    <div className='contact-card-wrapper'>
                        <h2 className='text-center'>Contact us</h2>

                        <p>Call to discuss a free consultation, or email us photos and we will get back to you as soon as we can.</p>

                        <ul className='contact-list'>
                            <li className='phone'>
                                <a href='tel:07794 382378'>07794 382378</a>
                            </li>
                            <li className='email'>
                                <a href='mailto:jjbarneytreecare@gmail.com'>jjbarneytreecare@gmail.com</a>
                            </li>
                            <li className='address'>
                                Godalming, Surrey
                            </li>
                        </ul>
                    </div>
                </div>
                {/* <iframe id='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2501.37573960865!2d-0.6489073835788273!3d51.17529727958185!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4875ce02323973a9%3A0xa0ea328e565ac580!2sJ%20J%20Barney%20%26%20Sons!5e0!3m2!1sen!2suk!4v1664378290199!5m2!1sen!2suk" width="600" height="450" allowFullScreen={false} loading="lazy" referrerPolicy="no-referrer-when-downgrade" title='google-map'></iframe> */}
            </div>
        </div>
    </section>
  )
}
