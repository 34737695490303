import React, { useState, useEffect } from 'react'
import PhoneIcon from '../../assets/imgs/phone-icon.svg'
import FbIcon from '../../assets/imgs/fb-icon.svg'
import InstagramIcon from '../../assets/imgs/instagram-icon.svg'

export default function Header() {
  const [isOpen = false, setIsOpen] = useState(0),
        [scrollDown, setScrollDown] = useState(false);

  useEffect(() => {
    isOpen && (document.body.style.overflow = 'hidden');
    !isOpen && (document.body.style.overflow = '');
  }, [isOpen]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const handler = () => {
        if (
          window.innerWidth > 1100 && 
          window.pageYOffset > 200
        ) {
          return true
        }
        else {
          return false
        }
      }
      window.addEventListener("scroll", () =>
        setScrollDown(handler)
      );
    }
  }, [scrollDown]);

  return (
    <div  className='navigation'>

      <nav className={isOpen ? 'show-nav' : ''}>
        <ul>
          <li><a href='/#' tabIndex='-1' onClick={() => setIsOpen(!isOpen)}>home</a></li>
          <li><a href='#services' tabIndex='-1' onClick={() => setIsOpen(!isOpen)}>services</a></li>
          <li><a href='#about-us' tabIndex='-1' onClick={() => setIsOpen(!isOpen)}>about us</a></li>
          <li><a href='#our-work' tabIndex='-1' onClick={() => setIsOpen(!isOpen)}>our work</a></li>
          <li><a href='#contact' tabIndex='-1' onClick={() => setIsOpen(!isOpen)}>contact</a></li>
        </ul>
        <a className='facebook' href='https://www.facebook.com/jjbarneytreecare/' target='_blank' rel='noreferrer' tabIndex='-1'>
          <img src={FbIcon} alt='Facebook logo' />
        </a>
        <a className='instagram' href='https://www.instagram.com/jjbarneytreecare/' target='_blank' rel='noreferrer' tabIndex='-1'>
          <img src={InstagramIcon} alt='Instagram logo' />
        </a>
      </nav>

      <header className={scrollDown ? 'container minimised' : 'container'}>
        <a href='/#'><h1>JJ Barney & Sons</h1></a>
        <div className='header-links'>
          <ul>
            <li><a href='/#'>home</a></li>
            <li><a href='#services'>services</a></li>
            <li><a href='#about-us'>about us</a></li>
            <li><a href='#our-work'>our work</a></li>
            <li><a href='#contact'>contact</a></li>
            <li><a href='tel:07794 382378'><img src={PhoneIcon} alt='phone'/></a></li>
          </ul>
        </div>
      </header>

      <div className={isOpen ? 'burger-menu cross' : 'burger-menu'} onClick={() => setIsOpen(!isOpen)}>
        <div className='burger-menu-container'>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
    
  )
}
