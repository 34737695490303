import CardImgOne from '../../assets/imgs/services-1.jpg'
import CardImgTwo from '../../assets/imgs/services-2.jpg'
import CardImgThree from '../../assets/imgs/services-3.jpg'
import CardImgFour from '../../assets/imgs/services-4.jpg'
import CardImgFive from '../../assets/imgs/services-5.jpg'
import CardImgSix from '../../assets/imgs/services-6.jpg'
import Card from '../Card'

const cards = [
    {
        img: CardImgOne,
        altText: 'man standing next to felled oak tree',
        title: 'Tree felling',
        text: 'Dismantle and removal, all sizes',
    },
    {
        img: CardImgTwo,
        altText: 'trimmed hedge row',
        title: 'Hedge maintenance services',
        text: 'Hedge cutting, removal and pruning',
    },
    {
        img: CardImgThree,
        altText: 'man standing next to felled oak tree',
        title: 'Tree maintenance',
        text: 'Tree thinning, pruning, reductions, dead wooding',
    },
    {
        img: CardImgFive,
        altText: 'man standing next to felled oak tree',
        title: 'Stump grinding',
        text: 'Removal of all sizes',
    },
    {
        img: CardImgSix,
        altText: 'man standing next to felled oak tree',
        title: 'Waste removal',
        text: 'We remove waste timber and foliage for the perfect finish',
    },
    {
        img: CardImgFour,
        altText: 'man standing next to felled oak tree',
        title: 'Emergency call-outs',
        text: 'Call us for a quick response for any issues',
    },
]

export default function Services() {
  return (
    <section className='services'>
        <div className='anchor-link' id='services'></div>
        <div className='container'>
            <h2 className='text-center'>Services</h2>
            <div className='cards'>
                {
                    cards.map((card, index) => {
                        return <Card key={index} img={card.img} altText={card.altText} title={card.title} text={card.text} />
                    })
                }
            </div>
        </div>
    </section>
  )
}
